import { Link, PageProps } from 'gatsby'
import React from 'react'

const Naac: React.FC<PageProps> = ({}) => {
	return (
		<div>
			<div>
				<iframe
					style={{ width: '100%', height: '40rem' }}
					src='https://docs.google.com/forms/d/e/1FAIpQLSdCugHAnM9uBurZ32-Q8yiFSvrkvRIGAyIMorEk4qlfqmeBBw/viewform?embedded=true'
				>
					Loading…
				</iframe>
			</div>
		</div>
	)
}

export default Naac
